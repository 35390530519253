import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import BlackFridayImg from 'images/blog-images/black-friday.png';
import ShoppingSeasonsFeatured from 'images/featured-image/black-friday-featured.png';
import BacktoSchoolImg from 'images/blog-images/back-to-school.png';


interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query ShoppingSeasonImg {
      file(relativePath: { eq: "black-friday.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="get prepared for shopping seasons 2020"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="Get Prepared for Shopping Seasons in 2020 | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-get-prepared" })}
         meta={
          [
            {
              name: `description`,
              content: "",
            },
            {
              property: `og:title`,
              // content: "Get Prepared for Shopping Seasons in 2020",
              content: intl.formatMessage({ id: "blog-post-meta-title-get-prepared" }),
            },
            {
              property: `og:description`,
              content: "",
            },
            {
              property: `og:image`,
              content: ShoppingSeasonsFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="get-prepared-for-shopping-seasons-in-2020"
        title="Get Prepared for Shopping Seasons in 2020"
        date="2020-08-19"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              Affected by the global epidemic in the first half of the year, there is a huge change in the market environment and consumer habits. We are going to sort out some important shopping seasons and data in overseas markets for eCommerce sellers in the second half of the year. It is important to prepare your logistics operations properly well ahead of the shopping season. Take your time to plan a shipping strategy accordingly so you don’t get overwhelmed.
            </p>

            <h5>
              <strong>
                September school season
              </strong>
            </h5>

            <p>
              The 2020 school season kicked off the promotion of the second half of the year. At the beginning of the school season, no matter whether students still need to study at home or not, the habit of buying school supplies at the beginning of school will not change. Brands providing school supplies should not miss the chance for doing marketing.
            </p>

            <p>
              The keyword "Back to School item" in the first school season of 2019, the search volume of this keyword in the UK has increased by 90%, and the search volume in the US has increased by 28%. Among them, searches for discount information on boy’s school uniforms and shoes in the UK surged by 134% compared with 2018.
            </p>

            <p>
              This year, products related to distance learning have become a hot-selling item. In the last school season, March 2020, "online education" and "home study" became parents' most concerning issues. The related searches increased by 100% compared to the beginning of this year.
            </p>

            <Image src={BacktoSchoolImg} className="" alt="back to school shopping"/>

            <h5>
              <strong>
                Black Friday
              </strong>
            </h5>

            <p>
              Thanksgiving Day is the fourth Thursday in November every year, so its second day is also the first day of American purchases. On this day, whether it is physical stores or online merchants, sellers will provide a great discount during the Black Friday promotion period.
            </p>

            <p>
              Shopping malls in the United States generally record deficits with red pens and black pens for profits, and the Friday after Thanksgiving, people’s frantic rush to purchase makes mall profits soar, so it is called Black Friday by merchants. Online sales of 7.4 billion U.S. dollars on Black Friday in 2019 became the highest sales day in the history of Black Friday. The average order value of consumers was 168 U.S. dollars, an increase of nearly 6% year-on-year, which also set a new record for Black Friday.
            </p>

            <p>
              Black Friday is a good time for brands to build brand awareness before the holiday sales season of Christmas and New Year, and it is also a good opportunity to test whether a new brand sales strategy is feasible. Many consumers began to study their holiday shopping plans as early as the beginning of November. Data shows that starting from November, the search volume for "Black Friday discounts" on Google began to increase significantly.
            </p>

            <p>
              Black Friday is also a peak season for electronic products, including TVs, laptops, game consoles and smartphones. Searches for Black Friday laptop discounts in the UK in 2019 increased by 139% compared to 2018, TVs increased by 115%, and smart watches increased by 197%. Searches for discounts on laptops and TVs in the United States also peaked throughout the year, more than ten times higher than in other periods.
            </p>

            <p>
              Therefore, sellers can only enter the range of consumers' choices and plans to purchase, and can effectively adjust their sales strategies only if they prepare well in advance.
            </p>

            <Image src={BlackFridayImg} className="" alt="black friday shopping"/>

            <h5>
              <strong>
                Year-end promotion for the Christmas season
              </strong>
            </h5>

            <p>
              Christmas shopping season is a good time for overseas consumers to empty their shopping carts. They may not only purchase for themselves, but also purchase gifts for their family and friends. Therefore, consumers are more receptive to new things at Christmas.
            </p>

            <p>
              The Christmas discount season in European and American markets is more like a marathon, lasting from before Christmas to after New Year's Day. Price cuts and discounts are a common magic weapon for retailers all over the world during the Christmas period. Many businesses focus on promotional methods such as discounts, free gifts and zero down payment installments. 
            </p>

            <p>
              In 2019, 50% of consumers bought products in new stores, and 73% said they would like to buy products in stores they had never visited before. In 2019, the download volume of e-commerce apps increased by 49% after the holiday. 
            </p>

            <p>
              Shopping seasons are the busiest time of the year for online sellers and shipping companies. During these seasons people tend to order a lot more than usual. Therefore, it is important that sellers pay attention to the different aspects regarding shipping so they can be able to live up to the customers’ expectations.
            </p>

            <BottomShare>
              <div className="column-half">
                <p>Share the article</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="Facebook Icon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="Twitter Icon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="Linked Icon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;